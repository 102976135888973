<script setup lang="ts">
const head: any = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

const htmlAttrs = computed(() => head.value.htmlAttrs!)

const route = useRoute()

const refCode: any = useCookie('refCode')
// const refCode: any = useCookie('refCode', { maxAge: 86400 })

refCode.value = refCode.value || route?.query?.ref

// const url = useRequestURL()

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const scrollTopButton = ref<any>(null)

function handleScroll() {
  if (window.scrollY > 0) {
    scrollTopButton.value.classList.remove('invisible')
  }
  else {
    scrollTopButton.value.classList.add('invisible')
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir" class="layout1">
    <!-- <Head>
      <template v-for="link in head?.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="`${url.href}${link.href}`" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head?.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head> -->
    <Body>
      <Layout1Header />

      <div class="relative pt-20 pb-16">
        <slot />
      </div>

      <Layout1Footer />

      <div ref="scrollTopButton" class="cursor-pointer invisible items-center justify-center bg-sky-500 text-white w-12 h-12 rounded-full fixed bottom-20 right-2 left-auto z-[999] transition shadow-md" style="display: flex;" @click="scrollToTop">
        <span class="w-3 h-3 border-t border-l border-white rotate-45 mt-[6px]" />
      </div>

      <GeminiAssistant />

      <CookieSection />
    </Body>
  </Html>
</template>
